import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFolder } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'gatsby';
import Title from '@/components/Title';

export default function GalleryDetails() {
  return (
    <>
      <Title>Details</Title>
      <ul className="">
        {[
          {
            id: 1,
            title: `Miau MiauMiauMiau Miau MiauMiauMiauMiauMiauMiau Miau MiauMiau Miau`,
            description: `Lorem Ipsum`,
            src: `https://loremflickr.com/2000/3000`,
          },
          {
            id: 2,
            title: `Miau`,
            description: `Lorem Ipsum`,
            src: `https://loremflickr.com/3000/2000`,
          },
          {
            id: 3,
            title: `Miau`,
            description: `Lorem Ipsum`,
            src: `https://loremflickr.com/3000/2000`,
          },
        ].map((item: any) => (
          <li key={item.id} className="block mb-12 ">
            <img
              src={item.src}
              style={{ maxWidth: `100vh`, maxHeight: `100vh` }}
              alt="placeholder"
            />
            <div className="block w-full">
              <span className="text-white ">{item.title}</span>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
