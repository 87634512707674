import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFolder } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'gatsby';
import GalleryDetails from '@/components/GalleryDetails';
import LayoutMain from '@/components/Layouts/LayoutMain';

export default function Details() {
  return (
    <LayoutMain>
      <GalleryDetails />
    </LayoutMain>
  );
}
